
import Vue from 'vue'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Opportunity',
  components: {
    BoosterPicture
  },
  props: {
    opportunity: {
      type: Object,
      default: () => {}
    },
    index: {
      type: String,
      default: '-1'
    },
    fromRadar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      $gtm: null,
      section: 'company'
    }
  },
  computed: {
    ita() {
      return this.opportunity?.company?.coid === 594
    },
    formattedDescription() {
      const description = this.opportunity.description
      let cleanDescription = description
      cleanDescription = cleanDescription.replace(/<\/?[^>]+(>|$)/g, '')
      // Rimuovi 'Descrizione:'
      if (cleanDescription.includes('Descrizione:')) {
        cleanDescription = cleanDescription.replace('Descrizione:', '')
      }

      // Taglia tutto ciò che viene dopo 'Requisiti'
      if (cleanDescription.includes('Requisiti')) {
        cleanDescription = cleanDescription.split('Requisiti')[0]
      }

      return cleanDescription.trim()
    },

    getTags() {
      const tags = []

      // Aggiungi 'RAL Max' se maggiore di 0
      if (this.opportunity.ral_max > 0) {
        tags.push(`${this.opportunity.ral_max} RAL`)
      }
      // Aggiungi il tipo di contratto
      if (tags.length < 9 && this.opportunity.contract_type) {
        tags.push(this.opportunity.contract_type)
      }
      // Aggiungi le skills se presenti
      if (tags.length < 9 && this.opportunity.skills && this.opportunity.skills.length > 0) {
        tags.push(...this.opportunity.skills)
      }
      // Aggiungi i benefici se presenti e capitalizzati
      if (tags.length < 9 && this.opportunity.benefits && this.opportunity.benefits.length > 0) {
        this.opportunity.benefits.forEach((benefit) => {
          // Capitalizza la prima lettera di ogni beneficio
          const capitalizedBenefit = benefit.charAt(0).toUpperCase() + benefit.slice(1)
          tags.push(capitalizedBenefit)
        })
      }
      // Aggiungi le lingue se presenti
      if (tags.length < 9 && this.opportunity.languages && this.opportunity.languages.length > 0) {
        tags.push(...this.opportunity.languages)
      }
      // Aggiungi le certificazioni se presenti
      if (tags.length < 9 && this.opportunity.certifications && this.opportunity.certifications.length > 0) {
        tags.push(...this.opportunity.certifications)
      }
      // Aggiungi il lavoro remoto se presente
      if (tags.length < 9 && this.opportunity.remote_working) {
        tags.push(this.opportunity.remote_working)
      }

      // Aggiungi la seniority se presente
      if (tags.length < 9 && this.opportunity.seniority) {
        tags.push(this.opportunity.seniority)
      }
      // Aggiungi le ore lavorative se presenti
      if (tags.length < 9 && this.opportunity.working_hours) {
        tags.push(this.opportunity.working_hours)
      }
      // Aggiungi 'categoria protetta' se protetta
      if (tags.length < 9 && this.opportunity.protected_category) {
        tags.push('categoria protetta')
      }

      return tags
    },
    cities() {
      // Controlla se 'city' è una stringa e separa le città se contiene '|'
      return this.opportunity.city
        ? this.opportunity.city.split('|')
        : []
    },
    getCities() {
      // Restituisce le città separate da virgola per tooltip
      return this.cities.length > 1 ? this.cities.join(', ') : ''
    }
  },
  methods: {
    getMedia(url, date) {
      const parts = url.split('/')
      const updatedUrl = parts.slice(4).join('/')
      return '/' + updatedUrl + '?updateTs=' + date
    },
    openLink(link) {
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    trackAndRedirect() {
      this.$gtm.push({
        event: 'click_job_application',
        event_data: {
          company_name: this.opportunity?.company?.co_name,
          job_application_name: this.opportunity.title,
          job_application_place: this.opportunity.city,
          is_stage: this.opportunity.id_contract_type === 8,
          verified: this.opportunity.verified,
          orp: this.opportunity.p2p,
          orp_value: this.opportunity.amountunlock,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
      this.$router.push(this.localePath({ name: 'radar-city-slug', params: { city: this.opportunity?.cityslug || 'no-city', slug: this.opportunity?.slug }, query: { ...this.$route?.query, id: this.opportunity.cid, ea: '0' } }))
    }
  }
})
