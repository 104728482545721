
import Vue from 'vue'

export default Vue.extend({
  name: 'News',
  props: {
    news: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    openLink(link) {
      this.track()
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    track () {
      this.$gtm.push({
        event: 'click_news',
        event_data: {
          news_title: this.news?.title?.rendered,
          news_url: this.news?.link,
          page_url: this.$router?.currentRoute?.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})
